import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby"
import { format } from "date-fns"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/styles/_index.scss';
import { areaguidesPageURL } from '../../site/urls';

const MoreAreasDetails = ({areaData}) => {

    const data = useStaticQuery(graphql`
        query GetAllAreas {
            glstrapi {
                areaGuides(limit:5) {
                    id
                    name
                    slug
                    short_description
                    tile_image {
                      url
                    }
                }
            }
        }
    `)

    const allAreas = areaData ? areaData : data.glstrapi.areaGuides

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <div className="areas-details-more-wrapper">
            <Container>
                <Row>
                    <Col>
                    <h2>More Dubai Communities</h2>
                    </Col>
                </Row>
            <Row>
                <Col>
                    <Slider className="areas-slider" {...settings}>
                        {
                            allAreas.map((data, i) => {

                                return (
                                    <div className="areas-card-wrap">
                                        <div className="areas-card-img-zoom">
                                            <Link to={areaguidesPageURL+data?.slug}>
                                                <img src={data?.tile_image?.url} alt={data.name + "- White & Co Real Estate"} />
                                            </Link>
                                        </div>
                                        <div className="areas-card-content">
                                            <div className="areas-heading">{data?.name}</div>
                                            <p>{data?.short_description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default MoreAreasDetails